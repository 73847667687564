.app {
	text-align: center;
	background-color: '#9e9e9e';
	height: 100%;
	max-width: 1440px;
	margin: 0 auto;
}

.header-container {
	min-height: 10vh;
	max-width: 1440px;
	margin: 0 auto;
}
.body-container {
	max-width: 1440px;
	margin: 0 auto;
}
.footer-container {
	max-width: 1440px;
	margin: 0 auto;
}

.app-logo {
	height: 40vmin;
	pointer-events: none;
}

.app-header {
	min-height: 10vh;
}
.app-content {
	margin-left: '10px';
	margin-right: '10px';
}

@media (prefers-reduced-motion: no-preference) {
	.app-logo {
		animation: app-logo-spin infinite 20s linear;
	}
}

@keyframes app-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
