@font-face {
	font-family: 'Roboto';
	src: local('Roboto'), url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

body {
	overflow: auto;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
